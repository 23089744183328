.login .login_btn button, .login a{
    width: 100%;
}
.login .email_input{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.login .email_input label{
    display: block;
    font-size: 12px;
    color: rgba(55, 53, 47, 0.65);
    font-weight: 500;
}
.login .email_input .textbox{
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 15px;
    line-height: 26px;
    position: relative;
    border-radius: 4px;
    box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px inset;
    background: rgba(242, 241, 238, 0.6);
    cursor: text;
    padding: 4px 10px;
    margin-top: 4px;
    margin-bottom: 8px;
    outline: none;
    border: none;
}
.login .email_input .textbox.error{
    border: 1px solid red;
    background-color: #ff00000f;
}
.login h2{
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 24px;
    text-align: center;
    line-height: 1.3;
    max-width: 380px;
}
.login .support_links{
    display: inline;
    color: inherit;
    user-select: none;
    cursor: pointer;
    color: rgba(55, 53, 47, 0.65);
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 8px;
    text-align: center;
}