/* Overlay for popup */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Popup content container */
.popup-content {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    width: 90%;
    width: 700px;
    max-height: 80vh; /* Limit height for scrolling */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    overflow-y: auto; /* Enables scrolling when content overflows */
    text-align: left;
    position: relative;
}

/* Close button */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    border: none;
    background: none;
    cursor: pointer;
}

/* Title */
h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
    text-align: left;
}

/* Paragraph styling */
p {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
    text-align: left;
}

/* Form group styling */
.form-group {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Labels */
.form-group label {
    width: 30%;
    font-weight: 500;
    color: #444;
    text-align: left;
}

/* Title field */
.form-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
}

/* Input fields */
.form-group input[type="text"],
.form-group input[type="date"],
.form-group input[type="file"],
.form-group select {
    width: 70%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

/* Radio buttons */
.radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 70%;
}

.radio-group label {
    display: flex;
    align-items: center;
    font-weight: 400;
    margin: 0;
}

.radio-group input[type="radio"] {
    margin-right: 6px;
}

/* Checkbox styling */
.form-group .checkbox-label {
    display: flex;
    align-items: center;
    margin-right: 6px;
}

.form-group input[type="checkbox"] {
    margin-right: 8px;
}

/* Toggle switch container */
.form-group .toggle-container {
    width: 70%;
    display: flex;
    align-items: center;
    margin-left: 30%;
}

/* Toggle switch */
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 200px;
    height: 28px;
    margin-top: 8px;
}

.toggle-switch input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-label {
    position: absolute;
    cursor: pointer;
    background-color: #ccc;
    border-radius: 24px;
    width: 100%;
    height: 100%;
    transition: background-color 0.3s;
    margin-right: 20%;
}

.toggle-label:before {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    top: 2px;
    left: 2px;
    transition: transform 0.3s;
}

.toggle-switch input[type="checkbox"]:checked + .toggle-label {
    background-color: #2196F3;
}

.toggle-switch input[type="checkbox"]:checked + .toggle-label:before {
    transform: translateX(32px);
}

/* Button styling */
.button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.submit-button {
    background-color: blue;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: darkblue;
}

/* Action section */
.action-section {
    margin-top: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.action-title {
    font-size: 18px;
    font-weight: bold;
}

.action-description {
    margin-bottom: 10px;
}

.action-button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.action-button:hover {
    background-color: #0056b3;
}
