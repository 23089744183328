.react-flow__node-ResizableNode {
  width: 1200;
  height: 600;
  position: relative;

  .labelContainer {
    position: absolute;
    left: 16px;
    top: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;

    input {
      font-size: 12px;

      &:focus {
        outline: unset;
      }
    }
  }

  .overlay {
    position: absolute;
    left: 0px;
    top: -54px;

    .overlayMenu {
      background-color: #001c39;
      display: flex;
      border-radius: 6px;
      overflow: hidden;
      font-size: 14px;

      button {
        padding: 12px 18px;
        color: #ffffff;

        &:hover {
          background-color: #26486a;
        }
      }
    }

    .colorPicker {
      margin-top: 8px;
      background-color: #001c39;
      display: flex;
      padding: 8px;
      justify-content: space-between;
      gap: 4px;
      border-radius: 6px;

      .colorVariant {
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }

    .colorDropdownBtn {
      display: flex;
      align-items: center;
      gap: 4px;

      .activeColor {
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }

      .icon {
        color: white;
      }
    }
  }
}
