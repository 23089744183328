/* Navbar */
.navbar {
  height: 60px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #007acc; /* Example background for the navbar */
  z-index: 1100; /* Ensure it's above the sidebar */
  display: flex;
  align-items: center;
  padding: 0 20px;
  transition: top 0.5s ease; /* Add transition for smooth appearance */
}

.header {
  margin-bottom: 10px;
  background-color: #f9f9fb;
  text-align: center;
  font-weight: bold;
}

.header h2 {
  margin: 0;
  color: #000000; /* Example color */
  font-weight: bold;
}

/* Search bar and arrow container */
.search-bar-container {
  display: flex; /* Flex container to keep arrow and search bar on the same row */
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

/* Search bar */
.search-bar {
  display: flex;
  justify-content: center;
  flex-grow: 1; /* Make search bar take available space */
}

.search-bar input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 16px;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border 0.3s ease;
}

.search-bar input:focus {
  border-color: #00aaff;
}

/* Arrow styling */
.arrow {
  color: #3a3939; /* White text color for arrows */
  background-color: #f9f9fb;
  border: none;
  border-radius: 50%;
  width: 40px; /* Increased size of arrow */
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 24px; /* Increase size of arrow text */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.arrow:hover {
  /* background-color: #005999; Darker shade on hover */
  transform: translateY(-2px); /* Slight lift on hover */
}

/* Container for the entire category navigation */
.category-navigation {
  display: flex;
  align-items: center; /* Aligns arrows and category buttons in the center vertically */
  justify-content: space-between; /* Evenly space the arrows and category container */
  padding: 10px; /* Padding around the navigation for spacing */
  background-color: #f9f9fb; /* Optional: background color for the whole container */
}

/* Category container for scrolling */
.category-container {
  display: flex;
  overflow-x: auto; /* Allows horizontal scrolling */
  flex-grow: 1; /* Allows the category container to expand and fill space between arrows */
  margin: 0 10px; /* Space between the category container and arrows */
  padding: 5px 0; /* Optional padding for the buttons inside the container */
  scrollbar-width: none; /* For Firefox, hides scrollbar */
}

/* Hide scrollbar for WebKit (Chrome, Safari) */
.category-container::-webkit-scrollbar {
  display: none;
}

/* Category button styling */
.category-button {
  padding: 5px 15px; /* Spacing inside the buttons */
  margin: 0 5px; /* Space between buttons */
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
  white-space: nowrap;
  border: 1.5px solid #0060ab;
}

.category-button:hover {
  transform: translateY(-1px); /* Slight lift on hover */
}

/* Active state for selected category */
.category-button.active {
  color: white;
  background-color: #0060ab;
}

/* Sidebar styling */
.sidebar2 {
  position: fixed;
  top: 64px; /* Sidebar starts right below the navbar */
  right: -400px; /* Adjust for the width of the sidebar */
  width: 380px; /* Slightly narrower */
  height: calc(100% - 64px); /* Full height minus navbar height */
  background-color: #f9f9fb;
  box-shadow: -2px 0 15px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  z-index: 1000;
  padding: 18px; /* Reduced padding */
  border-radius: 15px 0 0 15px;
  overflow-y: auto;
}

.sidebar2.visible {
  right: 0; /* Slide into view */
}

/* Items grid display */
.items-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 cards in parallel */
  gap: 12px; /* Reduced space between cards */
  padding: 8px; /* Adjusted padding */
}

/* Styling for each card */
.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background-color: #ffffff; /* Default background color */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Slight shadow for elevation */
  transition: transform 0.2s ease, background-color 0.3s ease,
    box-shadow 0.3s ease; /* Smooth transition */
  cursor: pointer;
  border: 2px solid transparent; /* Border for selection effect */
}

.item:hover {
  /* transform: translateY(-1px);  */
  background-color: #f0f8ff;
}

.item.selected {
  border: 2px solid #1976d2;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  background-color: #1976d20b;
}

/* Image inside each card */
.item-image {
  width: 50px; /* Smaller image */
  height: 50px;
  border-radius: 50%; /* Circular profile image */
  object-fit: cover; /* Ensure the image fits inside the circle */
  margin-bottom: 8px; /* Reduced margin */
  border: 2px solid #ddd; /* Border around profile image */
}

.item span {
  font-size: 12px; /* Reduced font size */
  font-weight: bold;
  text-align: center;
}
