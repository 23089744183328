.react-flow__node-NormalNode {
    background: #FFFFFF;
    border-radius: 6px;
    padding: 12px 36px;
    font-size: 14px;
    border: none;
    box-shadow: 0px 8px 24px rgba(66, 74, 83, 0.12);

    &.selected {
        border: 1px solid #0060AB;
    }

    .react-flow__handle {
        background-color: #0060AB;
        height: 10px;
        width: 10px;
    }

    .react-flow__handle-top {
        top: -20px;
    }

    .react-flow__handle-bottom {
        bottom: -20px;
    }

    .react-flow__handle-right {
        right: -20px;
    }

    .react-flow__handle-left {
        left: -20px;
    }


    .overlay {
        position: absolute;
        left: 0px;
        top: -54px;

        .overlayMenu {
            background-color: #001C39;
            display: flex;
            border-radius: 6px;
            overflow: hidden;
            font-size: 14px;

            button {
                padding: 12px 18px;
                color: #FFFFFF;

                &:hover {
                    background-color: #26486A;
                }
            }
        }

        .colorPicker {
            margin-top: 8px;
            background-color: #001C39;
            display: flex;
            padding: 8px;
            justify-content: space-between;
            gap: 4px;
            border-radius: 6px;

            .colorVariant {
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
        }

        .colorDropdownBtn {
            display: flex;
            align-items: center;
            gap: 4px;

            .activeColor {
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }

            .icon {
                color: white;
            }
        }
    }
}