@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  max-width: 100%;
  overflow: hidden;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar:hover {
  width: 10px;
  background-color: "red";
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #8b8b8b;
}
/* styles.css */
.line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

input {
  outline: none;
}

/* .react-resizable-handle {
  height: 90vh;
  transform: none;
} */
.x-spreadsheet-toolbar {
  display: none;
}

.formbuilder-container .react-resizable {
  position: relative;
  z-index: 10 !important; 
}




