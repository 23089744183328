/* ProcessList.css */
.process-list {
  padding: 2rem;
  position: relative;
}

.button-group {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 0.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.table th, .table td {
  padding: 0.75rem;
  border: 1px solid #d0e9f5; /* Light blue border */
  text-align: left;
}

.table th {
  background-color: #e0f7fa; /* Light blue header background */
  color: #0277bd; /* Dark blue text */
  font-weight: 600;
}

.table tr:hover {
  background-color: #bbdefb; /* Slightly darker blue on hover */
}

.description-cell {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.action-icons {
  display: flex;
  gap: 0.5rem;
}

.action-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: transform 0.2s;
}

.action-icon:hover {
  transform: scale(1.1);
}

/* Blue color for buttons */
.button-group button {
  background-color: #0288d1; /* Light blue for button */
  color: white;
}

.button-group button:hover {
  background-color: #01579b; /* Darker blue on hover */
}

