.aicontainer {
  position: absolute !important;
  bottom: 12px !important;
  left: 30px !important;
  /* top: auto !important; */
  border: 1px solid #e6e6e6;
  backdrop-filter: blur(10px);
  border-radius: 4px;
  height: fit-content;
}

.aiheader-container {
  /* height: 300px; */
  width: 350px;
  background-color: rgba(255, 255, 255, 0.6); /* Use rgba for transparency */
  border-radius: 4px;
  max-height: 80vh;
  overflow: auto;
}
.button-trigger-ai {
  position: absolute;
  left: 10px;
  bottom: 10px;
  padding: 15px;
  background: linear-gradient(to right, #00bfb2 0%, #1b4e94 100%) !important;
  border-radius: 50%;
  color: white;
}
.ai-chat-area {
  border: 1px solid #e6e6e6;
  outline: none;
  padding: 8px;
  border-radius: 4px;
  background: transparent;
  font: 12px inter;
  max-height: 45vh;
  min-height: 100px;
}
.ai-chat-area::placeholder {
  font: 12px inter;
}

.ai-chat-area:focus {
  outline: 1px solid #3b82f6;
  box-shadow: 1px 3px 10px #8cb7ff;
}

.ai-generate-button {
  background: linear-gradient(to right, #00bfb2 0%, #1b4e94 100%) !important;
  color: white !important;
  border-radius: 30px !important;
  padding: 8px !important;
}
.ai-generate-button:disabled {
  background: #e6e6e6 !important;
  color: #b3b3b3 !important;
}
.ai-generate-button:disabled .text {
  color: #b3b3b3 !important;
}
